import { get } from 'lodash';
import { STATE_KEY_NAME } from './constants';

export const getRouting = state => state[STATE_KEY_NAME];
export const getCurrentMatch = state => getRouting(state).currentMatch;
export const getPreviousMatch = state => get(getRouting(state), 'previousMatches[1]', {});
export const getCurrentMatchPathname = state => get(getRouting(state), 'currentMatch.pathname', '');
export const getPreviousMatches = state => getRouting(state).previousMatches;
export const getRoute = state => getCurrentMatch(state).route;
export const getRouteParams = state => getCurrentMatch(state).params;
