import { find } from 'lodash';
import { createSelector } from 'reselect';
import { getAppData } from './app-data-selectors';
import { getCategories } from './categories-selectors';
import { getCurrentMatchPathname } from '../router/router-selectors';
import { getTotalPublicationsCount } from '../store/blog-stats/blog-stats-selectors';
import * as getHeaderLinksService from '../services/get-header-links';

export const getFeedAndCategoryLinks = createSelector(
  getCategories,
  getAppData,
  getTotalPublicationsCount,
  (categories, appData, totalPublicationsCount) =>
    getHeaderLinksService.getHeaderLinks(categories, appData, totalPublicationsCount, true),
);

export const getCategoryLinks = createSelector(getCategories, getAppData, (categories, appData) =>
  getHeaderLinksService.getHeaderLinks(categories, appData, 0, false),
);

export const getActiveLink = createSelector(
  getFeedAndCategoryLinks,
  getCurrentMatchPathname,
  (links, currentPath) => find(links, link => link.path === currentPath) || links[0],
);
