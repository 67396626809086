import { get, keyBy, map, find, includes } from 'lodash';
import { createSelector } from 'reselect';

export const getCategories = state => state.categories;

export const getCategoryIds = createSelector([getCategories], categories => map(categories, '_id'));

export const getCategoriesMap = createSelector([getCategories], categories => keyBy(categories, '_id'));

export const getCategoryCount = state => getCategories(state).length;

const getCategorySlug = (state, categorySlug) => categorySlug;

export const getFistCategoryId = state => get(getCategories(state), '[0]._id');

export const getCategory = (state, id) => getCategoriesMap(state)[id];

export const getCategoryBySlug = createSelector([getCategories, getCategorySlug], (categories, categorySlug) =>
  find(categories, category => includes(category.slugs, categorySlug) || includes(category.slugs, `${categorySlug}/`)),
);

export const getHighestRank = createSelector([getCategories], categories =>
  Math.max(-1, ...map(categories, category => category.rank)),
);
