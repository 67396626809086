import { flowRight } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from '../../../common/components/runtime-context';
import { getFeedAndCategoryLinks, getCategoryLinks } from '../../../common/selectors/category-links-selectors';
import withTranslate from '../../../common/hoc/with-translate';
import LinkList from '../../../link-list';
import { getShowAllPostsCategory, getShowPostCount } from '../../selectors/category-menu-selectors';

const CATEGORY_LIMIT = 50;

const CategoryMenu = ({ t, categories, showPostCount }) => (
  <LinkList
    links={categories.slice(0, CATEGORY_LIMIT).map(category => ({
      key: category.key,
      path: category.path,
      text: showPostCount
        ? `${category.text} ${t('category-link.post-count', { count: category.postAmount })}`
        : category.text,
    }))}
    emptyState={{ text: t('empty-state.no-categories') }}
  />
);

CategoryMenu.propTypes = {
  t: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
  showPostCount: PropTypes.bool.isRequired,
};

const mapRuntimeToProps = state => {
  const showAllPostsCategory = getShowAllPostsCategory(state);
  const categories = showAllPostsCategory ? getFeedAndCategoryLinks(state) : getCategoryLinks(state);

  return {
    categories,
    showPostCount: getShowPostCount(state),
  };
};

export default flowRight(connect(mapRuntimeToProps), withTranslate)(CategoryMenu);
